<i18n>
    {
        "en": {},
        "ru": {
            "set_note_name": "Введите название ноты",
            "notes_list": "Список нот",
            "no_results": "Ничего не найдено",
            "notes_selected": "Выбранные ноты",
            "notes_add" :"Включить ноты",
            "notes_exclude": "Исключить ноты",
            "notes_already_excluded": "Исключенные ноты",
            "only_selected": "Одновременно",
            "only_selected_text": "Результат фильтрации — ароматы, в которых одновременно присутствуют все отмеченные ноты."
        }
    }
</i18n>
<template>
    <BaseTabs
        ref="tabs"
        @change="onTabChange"
        scroll-nav-horizontal
        class="filter-notes__tabs"
        id="tabs-filter-by-notes">

        <BaseTab
            id="add-notes"
            class="perfume-page__description"
            :selected="activeTabName === 'add-notes'"
            counter-big
            :name="$t('notes_add')"
            :counter="selectedNotes.length">

            <div>
                <div class="filter-notes__checkbox-wrapper">
                    <BaseCheckbox
                        v-model="onlySelected"
                        :tooltipTitle="$t('only_selected')"
                        :tooltipContent="$t('only_selected_text')"
                        :tooltipInside="'right'"
                        value="daily">{{ $t('only_selected') }}

                    </BaseCheckbox>
                </div>

                <SearchField
                    v-model="query"
                    :is-loading="loading"
                    class="filter-notes__search-field"
                    :placeholder="$t('set_note_name')" />

                <div class="filter-notes__slider-title">
                    {{ $t('notes_list') }}

                    <div class="filter-notes__no-results"
                        v-if="!availableNotes.length" >
                        {{$t('no_results')}}
                    </div>
                </div>

                <div class="filter-notes__slider-wrapper"
                    v-if="availableNotes">

                    <BaseScrollSlider
                        @scroll.native="scroll"
                        :shadowed="isDeviceDesktop"
                        class="filter-notes">

                        <transition-group class="filter-notes__transition-wrapper"
                            tag="div"
                            name="list-complete">
                            <div class="filter-notes__slide list-complete-item"
                                v-for="(note) in availableNotes"
                                :key="'available' + get(note, 'code')">
                                <BaseNote
                                    @click.native="addToSelected(note)"
                                    class="filter-notes__note-item"
                                    v-if="note.name"
                                    :layout="isDeviceDesktop ? 'row' : 'block'"
                                    :name="note.name"
                                    prevent-link
                                    :url="note.htmlUrl"
                                    :photoUrl="note.picture && note.picture.url">
                                    <template v-slot:label>
                                        <svg-icon
                                            name="add-circle"
                                            class="base-note__icon-label base-note__icon-label" />
                                    </template>
                                </BaseNote>
                            </div>
                        </transition-group>
                    </BaseScrollSlider>
                </div>
                <div v-if="selectedNotes.length" class="filter-notes__slider-wrapper">
                    <div class="filter-notes__slider-title">
                        {{ $t('notes_selected') }}
                    </div>
                    <BaseScrollSlider
                        :shadowed="isDeviceDesktop"
                        v-if="selectedNotes.length"
                        class="filter-notes">

                        <transition-group class="filter-notes__transition-wrapper"
                            tag="div"
                            name="list-complete">
                            <div class="filter-notes__slide list-complete-item"
                                v-for="(note) in selectedNotes"
                                :key="'selected' + note.code">
                                <BaseNote
                                    @click.native="removeFromSelected(note)"
                                    class="filter-notes__note-item"
                                    :layout="isDeviceDesktop ? 'row' : 'block'"
                                    v-if="note.name"
                                    prevent-link
                                    :name="note.name"
                                    :url="note.htmlUrl"
                                    :photoUrl="note.picture && note.picture.url" >
                                    <template v-slot:label>
                                        <svg-icon
                                            name="delete-circle"
                                            class="base-note__icon-label base-note__icon-label" />
                                    </template>
                                </BaseNote>
                            </div>
                        </transition-group>
                    </BaseScrollSlider>
                </div>
            </div>
        </BaseTab>

        <BaseTab
            id="exclude-notes"
            :name="$t('notes_exclude')"
            :selected="activeTabName === 'exclude-notes'"
            counter-big
            :counter="excludedNotes.length">

            <div class="filter-slider__wrapper">
                <SearchField
                    v-model="query"
                    :is-loading="loading"
                    class="filter-notes__search-field filter-notes__search-field_excluded"
                    :placeholder="$t('set_note_name')" />

                <div class="filter-notes__slider-title">
                    {{ $t('notes_list') }}

                    <div class="filter-notes__no-results"
                        v-if="!availableNotes.length" >
                        {{$t('no_results')}}
                    </div>
                </div>

                <div class="filter-notes__slider-wrapper"
                    v-if="availableNotes">
                    <BaseScrollSlider
                        :shadowed="isDeviceDesktop"
                        @scroll.native="scroll"
                        class="filter-notes">
                        <transition-group class="filter-notes__transition-wrapper"
                            tag="div"
                            name="list-complete">
                            <div class="filter-notes__slide list-complete-item"
                                v-for="(note) in availableNotes"
                                :key="'filtered' + note.code">
                                <BaseNote
                                    @click.native="addToExcluded(note)"
                                    class="filter-notes__note-item"
                                    prevent-link
                                    :layout="isDeviceDesktop ? 'row' : 'block'"
                                    v-if="note.name"
                                    :name="note.name"
                                    :url="note.htmlUrl"
                                    :photoUrl="note.picture && note.picture.url">
                                    <template v-slot:label>
                                        <svg-icon
                                            name="add-circle"
                                            class="base-note__icon-label base-note__icon-label" />
                                    </template>
                                </BaseNote>
                            </div>
                        </transition-group>
                    </BaseScrollSlider>
                </div>

                <div v-if="excludedNotes.length" class="filter-notes__slider-wrapper">
                    <div v-if="excludedNotes.length" class="filter-notes__slider-title">
                        {{ $t('notes_already_excluded') }}
                    </div>
                    <BaseScrollSlider
                        :shadowed="isDeviceDesktop"
                        v-if="excludedNotes.length"
                        class="filter-notes">

                        <transition-group class="filter-notes__transition-wrapper"
                            tag="div"
                            name="list-complete">
                            <div class="filter-notes__slide list-complete-item"
                                v-for="(note) in excludedNotes"
                                :key="'excluded' + note.code">
                                <BaseNote
                                    @click.native="removeFromExcluded(note)"
                                    class="filter-notes__note-item"
                                    :layout="isDeviceDesktop ? 'row' : 'block'"
                                    prevent-link
                                    v-if="note.name"
                                    :name="note.name"
                                    :url="note.htmlUrl"
                                    :photoUrl="note.picture && note.picture.url" >
                                    <template v-slot:label>
                                        <svg-icon
                                            name="delete-circle"
                                            class="base-note__icon-label base-note__icon-label" />
                                    </template>
                                </BaseNote>
                            </div>
                        </transition-group>
                    </BaseScrollSlider>
                </div>
            </div>
        </BaseTab>
    </BaseTabs>
</template>

<script>
import get from 'lodash/get';
import difference from 'lodash/difference';
import compact from 'lodash/compact';
import throttle from 'lodash/throttle';
import includes from 'lodash/includes';
import filter from 'lodash/filter';
import { mapGetters } from 'vuex'

import PaginatedMixin from '@/mixins/paginated.mixin'

import BaseTabs from '@/components/ui/BaseTabs';
import BaseTab from '@/components/ui/BaseTab';
import BaseNote from '@/components/ui/BaseNote';
import BaseScrollSlider from '@/components/ui/BaseScrollSlider';
import SearchField from '@/components/layout/search/SearchField';

export default {
    name: 'FilterByNotes',
    components: { SearchField, BaseNote, BaseTab, BaseTabs, BaseScrollSlider },
    mixins: [ PaginatedMixin ],
    props: {
        notesToSelected: {
            type: Array,
            default: () => []
        },
        notesToExcluded: {
            type: Array,
            default: () => []
        },
        onlySelectedNotes: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            selectedNotes: [],
            excludedNotes: [],
            activeTabName: 'add-notes',
            onlySelected: false,
            disabledEvents: {
                notesToSelected: false,
                notesToExcluded: false
            },
            availableNotes: []
        }
    },
    computed: {
        ...mapGetters(['isDeviceDesktop']),
        notes() {
            return this.items
        },
        notAvailableNotes() {
            return [
                ...this.selectedNotes,
                ...this.excludedNotes
            ]
        }
    },
    watch: {
        onlySelectedNotes(value) {
            if (this.onlySelected !== value)
                this.onlySelected = value
        },
        selectedNotes(array) {
            if (!array.length) {
                this.onlySelected = false
            }
        },
        onlySelected(value) {
            this.$emit('only-selected', value)
        },
        notesToSelected(array) {
            if (!this.disabledEvents.notesToSelected) {
                this.selectedNotes = filter(this.notes, note => includes(array, note.code));
            }
        },
        notesToExcluded(array) {
            if (!this.disabledEvents.notesToExcluded) {
                this.excludedNotes = filter(this.notes, note => includes(array, note.code));
            }
        },
        notes(array){
            const items = this.selectedNotes.concat(this.excludedNotes)
            
            this.availableNotes = array.filter(item => {
                return item.code !== get(items.filter(note => note.code === item.code), '[0].code')
            });
        }
    },
    created() {
        this.onlySelected = this.onlySelectedNotes
        /* todo: сделать подгрузку нот */

        this.selectedNotes = filter(this.notes, note => includes(this.notesToSelected, note.code))
        this.excludedNotes = filter(this.notes, note => includes(this.notesToExcluded, note.code))

        const notesFilteredExcluded = difference(this.notes, this.excludedNotes);
        this.availableNotes = difference(notesFilteredExcluded, this.selectedNotes);
    },
    mounted() {
        this.updateTabs();
        this.disabledEvents.notesToSelected = true;
        this.disabledEvents.notesToExcluded = true;
    },
    methods: {
        get,
        updateTabs() {
            this.$nextTick(() => {
                this.$refs.tabs.init()
            })
        },
        addToSelected(note) {
            this.query = ''

            if (!this.exists(this.selectedNotes, note)) {
                this.selectedNotes.push(note);
                this.emitNotes('input', this.selectedNotes);
            }
        },
        addToExcluded(note) {
            this.query = ''

            if (!this.exists(this.excludedNotes, note)) {
                this.excludedNotes.push(note);
                this.emitNotes('select-excluded-notes', this.excludedNotes);
            }
        },
        removeFromSelected: throttle(function(note) {
            this.query = ''
            this.selectedNotes = this.filterNotes(this.selectedNotes, note)
            this.emitNotes('input', this.selectedNotes)
        }, 300),
        removeFromExcluded: throttle(function(note) {
            this.query = ''
            this.excludedNotes = this.filterNotes(this.excludedNotes, note)
            this.emitNotes('select-excluded-notes', this.excludedNotes)
        }, 300),
        filterNotes(notes, note) {
            return notes.filter(n => n.code !== note.code);
        },
        exists(notes, note) {
            const exists = notes.filter(n => n.code === note.code);
            return exists.length > 0;
        },
        emitNotes(eventName, notes) {
            this.$emit(
                eventName,
                compact(notes.map(note => note.code))
            );

            this.updateTabs()
        },
        clear() {
            this.selectedNotes = []
            this.excludedNotes = []

            this.updateTabs()
        },
        onTabChange(name) {
            this.activeTabName = name
        },
        scroll(e) {
            if (this.loading || !this.canLoad()) {
                this.faded = false;
                return false;
            }

            if (this.isDeviceDesktop) {
                if (e.currentTarget.scrollTop + e.currentTarget.clientHeight >= e.currentTarget.scrollHeight - 100) {
                    this.load();
                }
            } else if (e.currentTarget.scrollLeft + e.currentTarget.clientWidth >= e.currentTarget.scrollWidth - 50) {
                this.load();
            }
        },
    }
};
</script>

<style scoped>
.list-complete-item {
    transition: all .4s ease;
}
.list-complete-enter, .list-complete-leave-to
/* .list-complete-leave-active до версии 2.1.8 */ {
    opacity: 0;

    transform: translateX(50vw)
}
.list-complete-leave-active {
    position: absolute;
}
</style>

<style lang="less">


.filter-notes {
    padding-left: rem(24);

    position: relative;
    z-index: 0;

    opacity: 1;

    transition: opacity .15s ease-in;

    @{desktop} & {
        display: block;

        max-height: rem(252);

        overflow-x: hidden;
        overflow-y: scroll;
    }

    &__slide {
        flex: 0 0 auto;

        margin-right: rem(7);
        padding: rem(12) 0 0;

        height: auto;
        width: rem(60+32);

        @{desktop} & {
            padding-top: 0;

            width: 100%;

            position: static !important;

            transition: none !important;
        }
    }

    &__no-results {
        color: @color-gray-light;
        font-size: rem(14);

        padding-top: rem(10);
    }

    &__search-field {
        margin-bottom: rem(17);

        @{mobile} & {
            border-top: rem(1) solid @color-gray-white;
        }

        @{desktop} & {
            margin-top: rem(0);
            .search-field__field:before {
                top: rem(13);
                left: rem(17);
            }

            .form__input {
                background-color: @color-bg-gray-minor;
                border-color: @color-gray-white;

                padding-left: rem(40);

                height: rem(42);
            }
        }

        &_excluded {
            @{mobile} & {
                margin-top: rem(16);
            }

            @{desktop} & {
                margin-top: rem(16);
            }
        }
    }

    &__checkbox-wrapper {
        margin-top: rem(12);
        padding-top: rem(12);
        padding-bottom: rem(15);

        .page-padding();

        .help-button {
            position: absolute;
            right: 0;
            .page-padding();
        }
    }

    &__tabs {
        .tabs__nav {
            margin-bottom: 0;
        }

        .tabs__panels {
            padding: 0;
        }

        .tabs__link {

            @{desktop} & {
                padding-top: rem(8);
            }
        }
    }

    &_shown {
        opacity: 1;
    }

    &__note-item {
        cursor: pointer;

        .base-note {

            &__label {
                --size: rem(28);
                border: rem(1) solid @color-green;
                color: @color-green;
                font-size: rem(22);
                font-weight: 400;

                @{desktop} & {
                    --size: rem(24);
                    right: rem(1.5);
                }

                &_remove {
                    border: rem(1) solid @color-red;
                }

                &_remove svg {
                    position: relative;
                    top: rem(-1);
                }
            }

            &__icon-label {
                height: rem(28);
                width: rem(28);

                position: absolute;
                top: rem(-4);
                right: rem(9);
                z-index: 1;

                @{desktop} & {
                    height: rem(24);
                    width: rem(24);

                    top: auto;
                    right: rem(1);
                }
            }
        }
    }

    &__transition-wrapper {
        @{mobile} & {
            display: flex;
        }
    }

    &__slider-title {
        font-size: rem(15);

        margin: 0 0 rem(7);
        .page-padding();

        @{desktop} & {
            margin-bottom: rem(10);
        }
    }


    &__slider-wrapper {
        min-height: rem(103);

        @{mobile} & {
            position: relative;

            &:before {
                .gradient(left);
            }

            &:after {
                .gradient(right);
            }

            &:before,
            &:after {
                height: 100%;
                width: rem(31);

                top: auto;
                bottom: 0;
                z-index: 1;

                pointer-events: none;
            }
        }

        & + & {
            margin-top: rem(35);

            @{mobile} & {
                &:before,
                &:after {
                    height: calc(100% - rem(30));
                }
            }
        }
    }

    &__pagination-list {
        height: rem(300);
    }
}

.filter-checkbox {
    &__counter {
        color: @color-gray-light;
    }
}
</style>
