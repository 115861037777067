<i18n>
{
    "ru": {
        "gender": "Пол",
        "year": "Год",
        "new": "Новинки",
        "vintage": "Винтаж",
        "segment": "Сегмент",
        "brand": "Бренд",
        "set_brand_name": "Введите название бренда...",
        "group": "Семейство",
        "collection": "Коллекция",
        "group_only_selected": "Одновременно",
        "group_tooltip": "Результат фильтрации — ароматы, в которых одновременно присутствуют все отмеченные семейства.",
        "concentration": "Концентрация",
        "rating": "Рейтинг",
        "note": "Ноты",
        "persistence": "Стойкость",
        "persistence_verylow": "Крайне низкая (до&nbsp;30&nbsp;минут)",
        "persistence_low": "Низкая (от&nbsp;30&nbsp;мин)",
        "persistence_middle": "Средняя (до&nbsp;2&nbsp;часов)",
        "persistence_high": "Высокая (от&nbsp;2&nbsp;часов)",
        "persistence_veryhigh": "Очень высокая (от&nbsp;12&nbsp;часов)",
        "drag_thumb": "Перемещайте ползунок",
        "trail": "Шлейф",
        "trail_verylow": "Крайне незаметный",
        "trail_low": "Незаметный",
        "trail_middle": "Средний",
        "trail_high": "Заметный",
        "trail_veryhigh": "Очень заметный",
        "country": "Страна",
        "set_country_name": "Введите название страны...",
        "perfumer": "Парфюмер",
        "set_perfumer_name": "Введите имя парфюмера...",
        "status": "Статус",
        "status_limited": "Лимитированное издание",
        "status_produced": "В производстве",
        "status_notProduced": "Выпуск прекращен",
        "gender_note": "Данные от бренда-производителя"
    }
}
</i18n>
<template>
    <CatalogFilter class="perfumes-filter"
        ref="filter"
        :stickyCount="!isModal"
        @resetAll="resetAll"
        @submit="$emit('submit', $event)">
        <template #body>

            <CatFilterBlock v-if="fields.notes && isSelectionByNotes && isDeviceDesktop"
                :is-opened="true"
                :title="$t('note')"
                :counter="values.note.length"
                @reset="reset('note')">
                <template #block-body>
                    <FilterByNotes
                        ref="notes"
                        @input="values.note = $event"
                        @only-selected="values.note_only_selected = $event"
                        @select-excluded-notes="values.note_exclude = $event"
                        :only-selected-notes="values.note_only_selected"
                        :list="fields.notes"
                        :notesToSelected="values.note"
                        :notesToExcluded="values.note_exclude" />
                </template>
            </CatFilterBlock>

            <CatFilterBlock v-if="fields.genders"
                :title="$t('gender')"
                :counter="values.gender.length"
                :is-opened="true"
                @reset="reset('gender')">
                <template #block-body>
                    <div class="cat-filter-block__note">{{ $t('gender_note') }}</div>
                    <div class="perfumes-filter__gender">
                        <div v-for="value in fields.genders"
                            :key="value.code"
                            class="checkbox cat-filter-block__checkbox">
                            <input v-model="values.gender" 
                                type="checkbox"
                                :id="'gender_' + value.code"
                                :value="value.code" />
                            <label :for="'gender_' + value.code">
                                <html-link v-if="value.htmlUrl && !isModal"
                                    :url="value.htmlUrl">
                                    {{ value.name }}
                                </html-link>
                                <template v-else>{{ value.name }}</template>
                            </label>
                        </div>
                    </div>
                </template>
            </CatFilterBlock>

            <CatFilterBlock v-if="fields.collections && fields.collections.length"
                :title="$t('collection')"
                :counter="values.collection.length"
                :is-opened="isDeviceDesktop"
                @reset="reset('collection')">
                <template #block-body>
                    <PaginatedList
                        :list="{items: fields.collections}"
                        :columns-count="1"
                        :list-classes="{columns_checkboxes: true}"
                        class="perfumes-filter__collections-list">
                        <template v-slot:item="value">
                            <div class="checkbox">
                                <input v-model="values.collection" 
                                    type="checkbox"
                                    :id="'collection_' + value.code"
                                    :value="value.code" />
                                <label :for="'collection_' + value.code">{{ value.name }}</label>
                            </div>
                        </template>
                    </PaginatedList>
                </template>
            </CatFilterBlock>

            <CatFilterBlock v-if="fields.brands"
                :title="$t('brand')"
                :counter="values.brand.length"
                :is-opened="isDeviceDesktop"
                @reset="reset('brand')">
                <template #block-body>

                    <SearchField
                        class="perfumes-filter__search"
                        ref="brandsSearch"
                        v-model="brandQuery"
                        :placeholder="$t('set_brand_name')" />

                    <PaginatedList
                        :list="fields.brands"
                        :q="brandQuery"
                        :columns-count="1"
                        :list-classes="{columns_checkboxes: true}"
                        :force-faded="true"
                        @loaded="$refs.brandsSearch.loading = false"
                        class="perfumes-filter__brands-list">

                        <template v-slot:item="value">
                            <div class="checkbox">
                                <input v-model="values.brand" 
                                    type="checkbox"
                                    :id="'brand_' + value.code"
                                    :value="value.code" />
                                <label :for="'brand_' + value.code">
                                    <html-link v-if="value.htmlUrl && !isModal"
                                        :url="value.htmlUrl">
                                        {{ value.name }}
                                    </html-link>
                                    <template v-else>{{ value.name }}</template>
                                </label>
                            </div>
                        </template>

                    </PaginatedList>
                </template>
            </CatFilterBlock>

            <CatFilterBlock v-if="fields.groups"
                :title="$t('group')"
                :counter="values.group.length"
                :is-opened="isDeviceDesktop"
                @reset="reset('group')">
                <template #block-body>

                    <div class="checkbox perfumes-filter__together">
                        <input v-model="values.group_only_selected" 
                            type="checkbox"
                            :id="'group_only_selected'"
                            value="y" />
                        <label :for="'group_only_selected'">
                            {{ $t('group_only_selected') }}
                        </label>

                        <HelpButton
                            class="perfumes-filter__together-help"
                            :inside="true"
                            :placement="'right'"
                            :content="$t('group_tooltip')" />
                    </div>

                    <PaginatedList
                        :list="{items: fields.groups}"
                        :columns-count="1"
                        :list-classes="{columns_checkboxes: true}"
                        class="perfumes-filter__groups-list">

                        <template v-slot:item="value">
                            <div class="checkbox perfumes-filter__checkbox-picture">
                                <input v-model="values.group" 
                                    type="checkbox"
                                    :id="'group_' + value.code"
                                    :value="value.code" />
                                <label :for="'group_' + value.code">
                                    <img v-if="value.picture" 
                                        v-lazy-load :data-src="value.picture.url"
                                        :alt="value.name" />
                                    <html-link v-if="value.htmlUrl && !isModal"
                                        :url="value.htmlUrl">
                                        {{ value.name }}
                                    </html-link>
                                    <template v-else>{{ value.name }}</template>
                                </label>
                            </div>
                        </template>

                    </PaginatedList>
                </template>
            </CatFilterBlock>

            <CatFilterBlock v-if="fields.notes && !isSelectionByNotes"
                :title="$t('note')"
                :counter="values.note.length"
                :is-opened="isDeviceDesktop"
                @reset="reset('note')">
                <template #block-body>
                    <FilterByNotes
                        ref="notes"
                        @input="values.note = $event"
                        @only-selected="values.note_only_selected = $event"
                        @select-excluded-notes="values.note_exclude = $event"
                        :only-selected-notes="values.note_only_selected"
                        :list="fields.notes"
                        :notesToSelected="values.note"
                        :notesToExcluded="values.note_exclude" />
                </template>
            </CatFilterBlock>

            <CatFilterBlock v-if="fields.ratings"
                :title="$t('rating')"
                :counter="values.rating.length"
                @reset="reset('rating')">
                <template #block-body>
                    <div class="perfumes-filter__checkboxes">
                        <div v-for="value in fields.ratings"
                            :key="value.code"
                            class="checkbox cat-filter-block__checkbox">
                            <input v-model="values.rating" 
                                type="checkbox"
                                :id="'rating_' + value.code"
                                :value="value.code" />
                            <label :for="'rating_' + value.code">
                                <html-link v-if="value.htmlUrl && !isModal"
                                    :url="value.htmlUrl">
                                    {{ $options.filters.numberDeclension(value.name, $t('declensions.stars')) }}
                                </html-link>
                                <div v-else
                                    class="rating cat-filter-block__rating-stars"
                                    :data-value="value.code"></div>
                            </label>
                        </div>
                    </div>
                </template>
            </CatFilterBlock>

            <CatFilterBlock v-if="fields.perfumers"
                :title="$t('perfumer')"
                :counter="values.perfumer.length"
                @reset="reset('perfumer')">
                <template #block-body>

                    <SearchField
                        class="perfumes-filter__search"
                        v-model="perfumerQuery"
                        ref="perfumersSearch"
                        :placeholder="$t('set_perfumer_name')" />

                    <PaginatedList
                        :list="fields.perfumers"
                        :q="perfumerQuery"
                        :columns-count="1"
                        :list-classes="{columns_checkboxes: true}"
                        :force-faded="true"
                        @loaded="$refs.perfumersSearch.loading = false"
                        class="perfumes-filter__brands-list">

                        <template v-slot:item="value">
                            <div class="checkbox perfumes-filter__checkbox-picture">
                                <input v-model="values.perfumer" 
                                    type="checkbox"
                                    :id="'perfumer_' + value.code"
                                    :value="value.code" />
                                <label :for="'perfumer_' + value.code">
                                    <img v-if="value.picture" 
                                        v-lazy-load :data-src="value.picture.url"
                                        :alt="value.name" />
                                    <html-link v-if="value.htmlUrl && !isModal"
                                        :url="value.htmlUrl">
                                        {{ value.name }}
                                    </html-link>
                                    <template v-else>{{ value.name }}</template>
                                </label>
                            </div>
                        </template>

                    </PaginatedList>
                </template>
            </CatFilterBlock>

            <CatFilterBlock v-if="fields.countries"
                :title="$t('country')"
                :counter="values.country.length"
                @reset="reset('country')">
                <template #block-body>

                    <SearchField
                        class="perfumes-filter__search"
                        v-model="countryQuery"
                        ref="countriesSearch"
                        :placeholder="$t('set_country_name')" />

                    <PaginatedList
                        :list="fields.countries"
                        :q="countryQuery"
                        :columns-count="1"
                        :list-classes="{columns_checkboxes: true}"
                        :force-faded="true"
                        @loaded="$refs.countriesSearch.loading = false"
                        class="perfumes-filter__brands-list">

                        <template v-slot:item="value">
                            <div class="checkbox">
                                <input v-model="values.country" 
                                    type="checkbox"
                                    :id="'country_' + value.code"
                                    :value="value.code" />
                                <label :for="'country_' + value.code">
                                    <html-link v-if="value.htmlUrl && !isModal"
                                        :url="value.htmlUrl">
                                        {{ value.name }}
                                    </html-link>
                                    <template v-else>{{ value.name }}</template>
                                </label>
                            </div>
                        </template>

                    </PaginatedList>
                </template>
            </CatFilterBlock>

            <CatFilterBlock v-if="fields.segments"
                :title="$t('segment')"
                :counter="values.segment.length"
                @reset="reset('segment')">
                <template #block-body>
                    <div class="perfumes-filter__checkboxes">
                        <div v-for="value in fields.segments"
                            :key="value.code"
                            class="checkbox cat-filter-block__checkbox">
                            <input v-model="values.segment" 
                                type="checkbox"
                                :id="'segment_' + value.code"
                                :value="value.code" />
                            <label :for="'segment_' + value.code">
                                <html-link v-if="value.htmlUrl && !isModal"
                                    :url="value.htmlUrl">
                                    {{ value.name }}
                                </html-link>
                                <template v-else>{{ value.name }}</template>
                            </label>
                        </div>
                    </div>
                </template>
            </CatFilterBlock>

            <CatFilterBlock v-if="fields.concentrations"
                :title="$t('concentration')"
                :counter="values.concentration.length"
                @reset="reset('concentration')">
                <template #block-body>
                    <div class="perfumes-filter__checkboxes">
                        <div v-for="value in fields.concentrations"
                            :key="value.code"
                            class="checkbox cat-filter-block__checkbox">
                            <input v-model="values.concentration" 
                                type="checkbox"
                                :id="'concentration_' + value.code"
                                :value="value.code" />
                            <label :for="'concentration_' + value.code">
                                <html-link v-if="value.htmlUrl && !isModal"
                                    :url="value.htmlUrl">
                                    {{ value.name }}
                                </html-link>
                                <template v-else>{{ value.name }}</template>
                            </label>
                        </div>
                    </div>
                </template>
            </CatFilterBlock>

            <CatFilterBlock v-if="fields.years"
                :title="$t('year')"
                :counter="yearValueCount"
                @reset="reset('year')">
                <template #block-body>

                    <div class="perfumes-filter__checkboxes">
                        <div v-for="value in fields.statuses_by_year"
                            :key="value.code"
                            class="checkbox cat-filter-block__checkbox">
                            <input v-model="values[value.code]" 
                                type="checkbox"
                                :id="'year_' + value.code"
                                :value="value.code" />
                            <label :for="'year_' + value.code">
                                <html-link v-if="value.htmlUrl && !isModal"
                                    :url="value.htmlUrl">
                                    {{ $t(value.code) }}
                                </html-link>
                                <template v-else>{{ $t(value.code) }}</template>
                            </label>
                        </div>
                    </div>

                    <MultiRange
                        class="perfumes-filter__years-range"
                        v-model="yearRange"
                        :min="yearsRange[0]"
                        :max="yearsRange[1]"
                        :start-value="yearsRange"
                        ref="yearRange" />

                    <PaginatedList
                        :list="{items: fields.years}"
                        :columns-count="1"
                        :list-classes="{columns_checkboxes: true}"
                        :force-faded="true"
                        class="perfumes-filter__years-list">

                        <template v-slot:item="value">
                            <div class="checkbox">
                                <input v-model="values.year" 
                                    type="checkbox"
                                    :id="'year_' + value.code"
                                    :value="value.code" />
                                <label :for="'year_' + value.code"
                                    :class="{'checkbox__bold': value.code.length > 4}">
                                    <html-link v-if="value.htmlUrl && !isModal"
                                        :url="value.htmlUrl">
                                        {{ value.name }}
                                    </html-link>
                                    <template v-else>{{ value.name }}</template>
                                </label>
                            </div>
                        </template>

                    </PaginatedList>
                </template>
            </CatFilterBlock>

            <CatFilterBlock
                :title="$t('persistence')"
                :counter="(values.persistence ? 1 : 0)"
                @reset="reset('persistence')">
                <template #block-body>
                    <!-- eslint-disable-next-line -->
                    <span v-html="persistenceCaption"
                        class="perfumes-filter__range-caption"></span>
                    <RangeSlider
                        v-model="values.persistence"
                        :values="persistence.values"
                        legend-type="none"
                        :pieces="5"
                        :legend="persistence.legend" 
                        class="perfumes-filter__range"/>
                </template>
            </CatFilterBlock>

            <CatFilterBlock
                :title="$t('trail')"
                :counter="(values.trail ? 1 : 0)"
                @reset="reset('trail')">
                <template #block-body>
                    <!-- eslint-disable-next-line -->
                    <span v-html="trailCaption"
                        class="perfumes-filter__range-caption"></span>
                    <RangeSlider
                        v-model="values.trail"
                        :values="trail.values"
                        legend-type="none"
                        :pieces="5"
                        :legend="trail.legend" 
                        class="perfumes-filter__range"/>
                </template>
            </CatFilterBlock>

            <CatFilterBlock v-if="fields.statuses"
                :title="$t('status')"
                :counter="values.status.length"
                @reset="reset('status')">
                <template #block-body>
                    <div class="perfumes-filter__checkboxes">
                        <div v-for="value in fields.statuses"
                            :key="value.code"
                            class="checkbox cat-filter-block__checkbox">
                            <input v-model="values.status" 
                                type="checkbox"
                                :id="'status_' + value.code"
                                :value="value.code" />
                            <label :for="'status_' + value.code">
                                <html-link v-if="value.htmlUrl && !isModal"
                                    :url="value.htmlUrl">
                                    {{ $t('status_' + value.code) }}
                                </html-link>
                                <template v-else>{{ $t('status_' + value.code) }}</template>
                            </label>
                        </div>
                    </div>
                </template>
            </CatFilterBlock>

        </template>
    </CatalogFilter>
</template>

<script>
import { filter, includes } from 'lodash';

import PaginatedList from '@/components/ui/PaginatedList';
import MultiRange from '@/components/ui/MultiRange';
import HelpButton from '@/components/ui/HelpButton';
import RangeSlider from '@/components/ui/RangeSlider';
import SearchField from '@/components/layout/search/SearchField';
import FilterByNotes from '@/components/layout/filter/FilterByNotes';

import FilterMixin from '@/components/catalog/mixins/Filter.mixin';
import CatalogFilter from '@/components/catalog/filters/CatalogFilter';
import CatFilterBlock from '@/components/catalog/filters/parts/CatFilterBlock';

import Perfume from '@/models/catalog/perfume';

export default {
    name: 'PerfumesFilter',
    components: {
        CatalogFilter,
        CatFilterBlock,
        PaginatedList,
        MultiRange,
        SearchField,
        HelpButton,
        FilterByNotes,
        RangeSlider
    },
    mixins: [FilterMixin],
    props: {
        /**
         * Фильтр подбора по нотам
         * 
         * @type {Boolean}
         */
        isSelectionByNotes: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            values: {
                gender: [],

                year: [],
                new: false,
                vintage: false,
                year_from: null,
                year_to: null,

                collection: [],
                segment: [],
                brand: [],

                group_only_selected: false,
                group: [],

                concentration: [],
                rating: [],

                note: [],
                note_exclude: [],
                note_only_selected: false,

                persistence: null,
                trail: null,
                country: [],
                perfumer: [],
                status: []
            },
            q: null,
            yearRange: [],
            brandQuery: null,
            countryQuery: null,
            perfumerQuery: null,
            model: new Perfume,

            persistence: {
                values: {
                    veryLow: 0,
                    low: 25,
                    middle: 50,
                    high: 75,
                    veryHigh: 100,
                },
                legend: {
                    veryLow: this.$t('persistence_verylow'),
                    low: this.$t('persistence_low'),
                    middle: this.$t('persistence_middle'),
                    high: this.$t('persistence_high'),
                    veryHigh: this.$t('persistence_veryhigh'),
                }
            },
            trail: {
                values: {
                    veryLow: 0,
                    low: 25,
                    middle: 50,
                    high: 75,
                    veryHigh: 100,
                },
                legend: {
                    veryLow: this.$t('trail_verylow'),
                    low: this.$t('trail_low'),
                    middle: this.$t('trail_middle'),
                    high: this.$t('trail_high'),
                    veryHigh: this.$t('trail_veryhigh'),
                }
            },

            countDeclensions: this.$t('declensions.perfumes'),
            stopEvents: {
                changed: true
            }
        }
    },
    computed: {
        yearsRange() {
            return [
                parseInt(this.fields.years[this.fields.years.length-1].code), 
                parseInt(this.fields.years[0].code)
            ];
        },
        yearValueCount() {
            if (this.values.year.length > 0) {
                return this.values.year.length;
            };

            if (this.yearRange.length > 0) {
                return this.yearRange.filter(item => item).length;
            };

            let total = 0;
            for (const i in this.fields.statuses_by_year) {
                if (this.values[this.fields.statuses_by_year[i].code]) {
                    total += 1;
                }
            };

            return total;
        },
        persistenceCaption() {
            if (this.values.persistence) {
                return this.persistence.legend[this.values.persistence];
            }
            return this.$t('drag_thumb');
        },
        trailCaption() {
            if (this.values.trail) {
                return this.trail.legend[this.values.trail];
            }
            return this.$t('drag_thumb');
        },
        filtersCount() {
            const filteredValues = filter(
                this.values,
                (f, key) => {
                    let exists = false;
                    if (!includes([
                        'year_from',
                        'accord_only_selected',
                        'note_only_selected',
                        'group_only_selected'
                    ], key)) {
                        if (Array.isArray(f) && f.length > 0 || !Array.isArray(f) && f) {
                            switch (key) {
                            case 'country':
                                exists = this.fields.countries;
                                break;
                            case 'brand':
                                exists = this.fields.brands;
                                break;
                            case 'concentration':
                                exists = this.fields.concentrations;
                                break;
                            case 'perfumer':
                                exists = this.fields.perfumers;
                                break;
                            case 'segment':
                                exists = this.fields.segments;
                                break;
                            case 'note':
                                exists = this.fields.notes;
                                break;
                            case 'group':
                                exists = this.fields.groups;
                                break;
                            case 'year':
                                exists = this.fields.years;
                                break;
                            default:
                                exists = true;
                                break;
                            }
                        }
                    }

                    return exists;
                }
            );

            return Object.keys(filteredValues).length;
        }
    },
    watch: {
        'values.year'(years) {
            if (years.length && this.fields.years) {
                this.resetStatusesByYear();
                this.yearRange = [];
                this.values.year_from = null;
                this.values.year_to = null;
                this.$refs.yearRange.reset();
            }
        },
        'values.new'(value) {
            if (value && this.fields.years) {
                this.yearRange = [];
                this.values.year_from = null;
                this.values.year_to = null;
                this.$refs.yearRange.reset();
                this.values.year = [];
            }
        },
        'values.vintage'(value) {
            if (value && this.fields.years) {
                this.yearRange = [];
                this.values.year_from = null;
                this.values.year_to = null;
                this.$refs.yearRange.reset();
                this.values.year = [];
            }
        },
        yearRange(values) {
            if (values.filter(item => item).length > 0) {
                this.values.year = [];
                this.values.year_from = parseInt(values[0]);
                this.values.year_to = parseInt(values[1]);
                this.resetStatusesByYear();
            }
        }
    },
    methods: {
        reset(id) {
            switch (id) {

            case 'persistence':
            case 'trail':
                this.values[id] = null;
                break;

            case 'brand':
                if (this.fields.brands) {
                    this.values[id] = [];
                    this[id + 'Query'] = null;
                }
                break;

            case 'perfumer':
            case 'country':
                if (this.fields[id]) {
                    this.values[id] = [];
                    this[id + 'Query'] = null;
                }
                break;

            case 'note':
                if (this.fields.notes) {
                    this.$refs.notes.clear();
                    this.values[id] = [];
                    this.values[id + '_exclude'] = [];
                }
                break;

            case 'group':
                if (this.fields.groups) {
                    this.values.group_only_selected = false;
                    this.values[id] = [];
                }
                break;

            case 'segment':
            case 'concentration':
                if (this.fields[id]) {
                    this.values[id] = [];
                }
                break;

            case 'year':
                if (this.fields.years) {
                    this.yearRange = [];
                    this.values[id] = [];
                    this.$refs.yearRange.reset();
                    this.values.year_from = null;
                    this.values.year_to = null;
                    this.resetStatusesByYear();
                }
                break;

            default:
                this.values[id] = Array.isArray(this.values[id]) ? [] : null;
                break;
            };
        },

        resetStatusesByYear() {
            if (this.fields.statuses_by_year) {
                for (const i in this.fields.statuses_by_year) {
                    this.values[this.fields.statuses_by_year[i].code] = false;
                }
            }
        }
    }
}
</script>

<style lang="less">
.perfumes-filter{

    &__brands-list,
    &__years-list,
    &__collections-list,
    &__groups-list{
        &.paginated-list{
            height: rem(188);
        }
    }

    &__gender{
        margin-top: rem(-3);
    }

    &__checkboxes{
        margin-top: rem(-19);
    }

    &__search{
        margin-top: rem(-4);
    }

    &__years-list{
        margin-top: rem(13);
    }

    &__years-range{
        margin-top: rem(37);
    }
    
    &__brands-list{
        margin-top: rem(20);
    }
    
    &__collections-list {
        margin-top: 0;
    }

    &__checkbox-picture{
        padding-top: rem(7);
    }

    &__together{
        position: relative;
        border-bottom: rem(1) solid @color-gray-white;
        padding-top: rem(4);
        padding-bottom: rem(19);

        &-help{
            &.help-button{
                position: absolute;
            }
            right: rem(3);
            top: rem(1);
        }
    }

    &__groups-list{
        margin-top: rem(19);
    }

    &__range-caption{
        color: @color-gray;
        font-size: rem(13);
        line-height: rem(19);
        min-height: rem(19);
        display: block;
        margin: rem(-3) 0 0;
    }

    &__range{
        margin: rem(6) 0 rem(-6);
    }

    @{mobile} & {
        .filter-notes__tabs{
            margin: rem(-7) rem(-20) 0;

            .search-field__form{
                margin-left: 0;
                margin-right: 0;
            }
        }
        .filter-notes__search-field{
            border-top: none;
        }
    }
}
</style>