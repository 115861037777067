<template>
    <figure class="range-slider"
        :class="classes">

        <header class="range-slider__header"
            v-if="showTooltip || title || isLegendInHeader && currentLegendItem"
            @click.stop>

            <figcaption class="range-slider__title"
                v-if="title">{{ title }}</figcaption>
            <span v-if="captionRight" class="range-slider__header-caption">{{ captionRight }}</span>

            <HelpButton class="range-slider__tooltip"
                v-if="showTooltip && (tooltipTitle || tooltipContent)"
                :button-text="tooltipButtonText"
                :button-event="tooltipButtonEvent"
                :tooltip-auto-hide="false"
                :tooltip-classes="tooltipClasses"
                :title="tooltipTitle"
                :content="tooltipContent"/>

            <transition
                mode="out-in"
                name="single-legend">
                <!-- eslint-disable-next-line -->
                <div v-html="currentLegendItem"
                    class="range-slider__single-legend"
                    v-if="isLegendInHeader && currentLegendItem"
                    :key="currentLegendItem">
                </div>
            </transition>

        </header>

        <p v-if="caption.length" class="range-slider__caption">{{ caption }}</p>

        <label class="range-slider__label"
            :aria-label="caption || 'input'"
            :id="cid">
        </label>

        <range-bar
            class="range-slider__bar"
            :track-color="trackColor"
            :thin="thin"
            v-model="rangeValue"
            :disabled="disabled"
            :pieces="pieces"
            :decrement-pieces="decrementPieces"
            :loaded="loaded"
            :min="min"
            :max="max"
            :startValue="startValue"
            @click.native="onInputClick"
            :step="stepValue"
            :aria-labelledby="cid" />

        <transition
            mode="out-in"
            name="single-legend">

            <!-- eslint-disable-next-line -->
            <div v-html="currentLegendItem"
                class="range-slider__legend-caption"
                v-if="isLegendCaptioned"
                :style="captionStyle"
                :key="currentLegendItem"></div>

        </transition>

        <component :is="legendTag"
            class="range-slider__multi-legend"
            v-if="isLegendInFooter">
            <!-- eslint-disable-next-line -->
            <span v-html="name"
                v-for="(name, index) in filteredLegend"
                :data-active="isActiveLegendItem(index)"
                :key="name">
            </span>
        </component>

    </figure>
</template>

<script>
import map from 'lodash/map'
import get from 'lodash/get'
import includes from 'lodash/includes'
import isNull from 'lodash/isNull'
import gsap from 'gsap'
import RangeBar from '@/components/ui/RangeBar'

import HelpButton from '@/components/ui/HelpButton'


export default {
    components: {
        HelpButton,
        RangeBar
    },
    model: {
        prop: 'value',
        event: 'range-change'
    },
    props: {
        // для приведения к макетам
        decrementPieces: {
            type: Boolean,
            default: false
        },
        boldLegend: {
            type: Boolean,
            default: false
        },
        trackColor: {
            type: String,
            default: 'violet',
        },
        min: {
            type: Number,
            default: 0
        },
        max: {
            type: Number,
            default: 100
        },
        pieces: {
            type: Number,
            default: 100
        },
        caption: {
            type: String,
            default: ''
        },
        captionRight: {
            type: [String, Number],
            default: ''
        },
        direction: {
            type: String,
            default: 'horizontal',
            validator(value) {
                return includes(['horizontal', 'vertical'], value)
            }
        },
        type: {
            type: String,
            default: 'values',
            validator(value) {
                return includes(['values', 'pieces'], value)
            }
        },
        disabled: {
            type: Boolean,
            default: false
        },
        tooltips: {
            type: Array,
            default: () => []
        },
        tooltipTitle: {
            type: String,
            default: ''
        },
        showTooltip: {
            type: Boolean,
            default: false
        },
        tooltipContent: {
            type: String,
            default: ''
        },
        tooltipButtonText: {
            type: String,
            default: null
        },
        tooltipClasses: {
            type: [String, Object, Array],
            default() {
                return []
            }
        },
        tooltipButtonEvent: {
            type: String,
            default: null
        },
        title: {
            type: String,
            default: ''
        },
        noActiveColor: {
            type: Boolean,
            default: false
        },
        legendShort: {
            type: Boolean,
            default: false
        },
        legendType: {
            type: String,
            default: 'footer',
            validator(value) {
                return includes(['header', 'footer', 'caption', 'none'], value)
            }
        },
        labelMax: {
            type: String,
            default: ''
        },
        legend: {
            type: [Array, Object],
            default: () => []
        },
        value: {
            type: [Number, String],
            default: 0
        },
        startValue: {
            type: [Number, String],
            default: null
        },
        values: {
            type: Object,
            default: () => {}
        },
        scrollTo: {
            type: String,
            default: ''
        },
        thin: {
            type: Boolean,
            default: false
        },
        titleSmall: {
            type: Boolean,
            default: false
        },
        titleNormal: {
            type: Boolean,
            default: false
        },
        titleBig: {
            type: Boolean,
            default: false
        },
        cid: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            inputValue: 0,
            stepValue: 0,
            loaded: false
        }
    },
    computed: {
        step() {
            return (this.max - this.min) / (this.pieces - 1)
        },
        captionStyle() {
            let transform = 'translateX(-50%)'
            let left = this.rangeValue + '%'

            if (this.rangeValue === 0) {
                transform = false
            } else if (this.rangeValue === 100) {
                transform = 'translateX(-100%)'
            } else if (this.rangeValue < 50) {
                left = this.rangeValue + 2 + '%'
            } else {
                left = this.rangeValue - 1.4 + '%'
            }

            return {
                transform,
                left
            }
        },
        currentLegendItem() {
            if (this.isTypePieces) {
                return this.rangeValue
            }

            return this.legend[this.value]
        },
        legendTag() {
            return this.isVertical ? 'aside' : 'footer'
        },
        rangeValue: {
            get() {
                if (this.values) {
                    if (!this.inputValue || this.value === null) return null
                    return this.values[this.inputValue]
                } else {
                    if (this.value === null) return null
                    return this.inputValue
                }
            },
            set(value) {
                if (this.values) {
                    this.inputValue = this.getKeyByValue(this.values, value)
                    this.$emit('range-change', this.inputValue)
                } else {
                    this.inputValue = value
                    this.$emit('range-change', value)
                }
            }
        },
        isTypePieces() {
            return this.type === 'pieces'
        },
        isLegendCaptioned() {
            return this.legendType === 'caption'
        },
        isLegendInHeader() {
            return this.legendType === 'header'
        },
        isLegendInFooter() {
            return this.legendType === 'footer'
        },
        classes() {
            return {
                'range-slider_vertical': this.isVertical,
                'range-slider_thin': this.thin,
                'range-slider_legend-footer': this.isLegendInFooter,
                'range-slider_horizontal': !this.isVertical,
                'range-slider_captioned': this.isLegendCaptioned,
                'range-slider_empty': !this.value,
                'range-slider_title_normal': this.titleNormal,
                'range-slider_title_small': this.titleSmall,
                'range-slider_bold-legend': this.boldLegend,
                'range-slider_title_big': this.titleBig
            }
        },
        isVertical() {
            return this.direction === 'vertical'
        },
        filteredLegend() {
            let legend = [];

            if (this.legendShort) {
                legend = map(this.legend, name => name[0])
            } else {
                legend = this.legend
            }

            return legend
        }
    },
    watch: {
        value(val) {
            if(!isNull(val)) {
                this.rangeValue = get(this.values, `[${val}]`, val)
            }
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.animateInitValue()
        })
    },
    methods: {
        /* todo: анимацию переделать */
        animateInitValue() {
            setTimeout(() => {
                gsap.to(this.$data, 0.4, { inputValue: this.value, loaded: true })

                setTimeout(() => {
                    this.stepValue = this.step
                }, 0)
            }, 500)
        },
        onInputClick(e) {
            this.$emit('input-click', e)
        },
        scrollToBlock() {
            if (this.scrollTo) {
                this.$scrollTo(this.scrollTo, 750, {
                    offset: -56,
                });
            }
        },
        isActiveLegendItem(index) {
            if (this.noActiveColor) return
            if (this.disabled) return

            if (this.legend.length === this.pieces) {
                return index * this.step === this.rangeValue
            } else {
                const legendValue = (index + this.pieces * index/this.legend.length) * this.step
                return legendValue === this.rangeValue
            }
        },
        getKeyByValue(object, value) {
            return Object.keys(object).find(key => {
                return parseInt(object[key]) === parseInt(value)
            });
        },
    },
}
</script>

<style scoped>
.single-legend-enter-active,
.single-legend-leave-active{
    transition-duration: .15s;
    transition-property: transform, opacity;
    transition-timing-function: ease-out;
}

.single-legend-enter, .single-legend-leave-to {
    opacity: 0;

    transform: scale(1.025);
}
</style>

<style lang="less">
.range-slider {
    --thumb-height: rem(22);
    --thumb-radius: 50%;
    --thumb-width: rem(22);
    --thumb-opacity: 0;
    --thumb-color: @color-primary;
    margin: 0;

    position: relative;

    &_legend-footer &__header {
        margin-bottom: rem(4);
    }

    &_title_small &__header {
        @{desktop} & {
            margin-bottom: rem(1);
        }
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    &__title {
        color: @color-black;
        font-size: rem(15);
        font-weight: 500;

        margin-right: rem(6);

        @{desktop} & {
            font-size: rem(15);
        }
    }

    &_title_big &__title {

        @{desktop} & {
            font-size: rem(16);

            margin-bottom: rem(2);
        }
    }

    &_title_normal &__title {
        font-size: rem(13);
        font-weight: 400;

        @{desktop} & {
            font-size: rem(13);
        }
    }

    &_title_small &__title {
        font-size: rem(13);
        font-weight: 500;

        @{desktop} & {
            font-size: rem(13);
        }
    }

    &__bar:not([disabled]) &__multi-legend {
        margin-top: rem(10);
    }

    &__bar:not([disabled]) {
        margin-top: rem(8);
        margin-bottom: 0;
    }

    &_title_small &__bar:not([disabled]) {
        margin-top: rem(7);
    }

    &__progress {
        min-width: rem(160);
    }

    &__header-caption {
        color: @color-gray-light;
        font-size: rem(13);
    }

    &__caption {
        color: @color-gray;
        font-size: rem(11);

        margin-top: rem(2);
        margin-bottom: rem(4);
    }

    &_thin &__caption {
        margin-top: rem(4);
        margin-bottom: rem(2);
    }

    &__single-legend {
        color: @color-gray;
        font-size: rem(13);
        line-height: 1.2;
        text-align: right;

        margin-left: auto;
        padding-left: rem(8);
    }

    &_bold-legend &__single-legend {
        color: @color-black;
        font-weight: 500;
    }

    &__multi-legend {
        display: flex;
        flex-flow: row no-wrap;
        justify-content: space-between;

        color: @color-gray;
        font-size: rem(13);

        margin-top: rem(-4);

        width: 100%;

        span {
            transition: color .2s ease;

            &[data-active] {
                color: @color-primary;
            }
        }

    }

    &_vertical &__multi-legend span {
        color: @color-black;
    }

    &_thin &__multi-legend {
        margin-top: rem(-10);
        margin-bottom: rem(14);
    }

    &_thin &__bar:not([disabled]) ~ &__multi-legend {
        margin-top: rem(-4);
        margin-bottom: rem(14);
    }

    &_captioned {
        padding-bottom: rem(21);
    }

    &__tooltip {
        top: rem(-1);
    }

    &__legend-caption {
        display: inline-block;

        color: @color-primary;
        font-size: rem(13);
        white-space: nowrap;

        position: absolute;
        bottom: 0;
    }

    &_horizontal &__bar {
        margin-bottom: rem(-4);
    }

    &_horizontal &__multi-legend {
        line-height: 1;

        padding-bottom: rem(15);
    }

    &_horizontal &__bar:not([disabled]) ~ &__multi-legend {
        padding-top: rem(7);
        padding-bottom: rem(11);
    }

    &_vertical {
        width: rem(25);
        min-height: rem(182);
    }

    &_vertical &__header {
        margin-bottom: rem(8);
    }

    &_vertical &__bar {
        min-width: rem(160);

        transform: rotateZ(90deg) translateY(rem(-10));
        transform-origin: left top;
    }

    &_vertical &__tooltip {
        transform: translateX(-55%) translateY(rem(4))
    }

    &_vertical &__multi-legend {
        flex-flow: column no-wrap;

        font-size: rem(11);

        min-height: rem(160);

        position: absolute;
        top: rem(5);
        left: rem(10);
    }

    &_empty {
        --thumb-color: @color-gray-light;
    }

    & + & {
        margin-top: rem(21);

        @{desktop} & {
            margin-top: rem(18);
        }
    }

    &_title_normal + & {
        margin-top: rem(12);
    }

    &__label{
        visibility: hidden;
        margin: 0;
        padding: 0;
        overflow: hidden;
        display: block;
    }
}
</style>
