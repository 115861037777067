<template>
    <input
        class="range-bar"
        :class="classes"
        :style="styles"
        v-model="rangeValue"
        :disabled="disabled"
        :min="min"
        :max="max"
        :step="step"
        type="range"
        @mousedown="prevent" />
</template>

<script>
import includes from 'lodash/includes'

export default {
    model: {
        prop: 'value',
        event: 'range-change'
    },
    props: {
        readonly: {
            type: Boolean,
            default: false
        },
        thin: {
            type: Boolean,
            default: false
        },
        pieces: {
            type: Number,
            default: 100
        },
        min: {
            type: Number,
            default: 0
        },
        step: {
            type: Number,
            default: 0
        },
        max: {
            type: Number,
            default: 100
        },
        loaded: {
            type: Boolean,
            default: true
        },
        // для приведения к макетам
        decrementPieces: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            default: null
        },
        value: {
            type: [Number, String],
            default: null
        },
        startValue: {
            type: [Number, String],
            default: null
        },
    },
    data() {
        return {
            inputValue: 0
        }
    },
    computed: {
        styles() {
            return {
                '--thumb-width': this.disabled ? this.thumbWidth : null
            }
        },
        thumbWidth() {
            const denominator = this.pieces - (this.decrementPieces ? 1 : 0)
            const value = (this.max - this.min) / denominator

            return value + '%'
        },
        rangeValue: {
            get() {
                if (this.value === null) {
                    return this.startValue;
                }
                return this.value;
            },
            set(value) {
                this.inputValue = value
                this.$emit('range-change', value)
            }
        },
        classes() {
            return {
                'range-bar_loaded': this.loaded,
                'range-bar_readonly': this.readonly,
                'range-bar_thin': this.thin,
                'range-bar_empty': includes([null, undefined], this.value),
            }
        },
    },
    methods: {
        prevent(e){
            e.stopPropagation()
        }
    }
}
</script>

<style lang="less">
.range-bar {
    --thumb-height: rem(22);
    --thumb-radius: 50%;
    --thumb-width: rem(22);
    --thumb-opacity: 0;
    --thumb-color: @color-primary;
    --track-height: rem(6);
    --track-color: @color-gray-white;
    --track-radius: calc(var(--track-height) / 2);
    --thumb-offset: rem(-8);

    cursor: pointer;

    background: none;
    box-shadow: none;

    height: rem(22);
    width: 100%;

    pointer-events: none;

    -webkit-appearance: none;
    -moz-appearance: none;

    &:focus {
        outline: none;
    }

    &[disabled] {
        --thumb-height: var(--track-height);
        --thumb-radius: var(--track-radius);
        --thumb-color: @color-light-violet;

        cursor: default;
        opacity: 1;
        pointer-events: none;
        --thumb-opacity: 1;

        .range-slider_bright &{
            --thumb-color: @color-violet;
        }
    }

    &_thin {
        --track-height: rem(4);
        --thumb-offset: rem(-9.5);
    }

    .track() {
        background-color: var(--track-color);
        border-radius: var(--track-radius);
        box-shadow: none;

        height: var(--track-height);
        width: 100%;
        -webkit-appearance: none;
        -moz-appearance: none;
        
        transition-duration: .1s;
        transition-property: background-color;
        transition-timing-function: ease;
    }

    .thumb() {
        background-color: var(--thumb-color);
        border: none;
        border-radius: var(--thumb-radius);
        box-shadow: none;

        height: var(--thumb-height);
        width: var(--thumb-width);

        opacity: var(--thumb-opacity);

        transition-duration: .1s;
        transition-property: border-radius, height, width, opacity, background-color;
        transition-timing-function: ease;

        transform-origin: center;
        -webkit-appearance: none;
        -moz-appearance: none;
    }


    &::-webkit-slider-runnable-track {
        .track();
    }
    &::-moz-range-track {
        .track();
    }

    &::-webkit-slider-thumb {
        .thumb();
    }
    &:not([disabled])::-webkit-slider-thumb {
        transform: translateY(var(--thumb-offset));
    }
    &::-ms-thumb {
        .thumb();
    }
    &::-moz-range-thumb {
        .thumb();
    }

    &_loaded {
        --thumb-opacity: 1;
        pointer-events: auto;
    }

    .hover({
        --track-color: @color-gray-light;
    });

    &_empty,
    &_readonly {
        --thumb-color: @color-gray-2;
        &[disabled] {
            .range-slider_bright &{
                --thumb-color: @color-gray-2;
            }
        }

        .hover({
            --thumb-color: @color-gray-light;
        });
    }
}
</style>
