<template>
    <div class="multi-range"
        :class="classes">
        <header class="multi-range__header">
            <span class="multi-range__value">{{minValue}}</span>
            <span class="multi-range__value">{{maxValue}}</span>
        </header>
        <div class="multi-range__form">
            <range-bar
                class="multi-range__bar"
                v-model="rangeValueOne"
                :readonly="readonly"
                :disabled="disabled"
                :min="min"
                :max="max"
                :value="valueOne"
                :start-value="valueOne" />
            <range-bar
                class="multi-range__bar"
                v-model="rangeValueTwo"
                :disabled="disabled"
                :readonly="readonly"
                :min="min"
                :max="max"
                :value="valueTwo"
                :start-value="valueTwo" />

            <span class="multi-range__line"
                :style="styles"> </span>
        </div>
    </div>
</template>
<script>
import min from 'lodash/min'
import max from 'lodash/max'
import debounce from 'lodash/debounce'
import RangeBar from '@/components/ui/RangeBar'

export default {
    name: 'MultiRange',
    components: {
        RangeBar
    },
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        min: {
            type: Number,
            default: 1900
        },
        max: {
            type: Number,
            default: 2020
        },
        readonly: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        value: {
            type: Array,
            default: () => []
        },
        startValue: {
            type: Array,
            default: () => []
        },
        startEmpty: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            stepValue: 0,
            loaded: false,
            valueOne: this.value[0] || this.startValue[0] || 0,
            valueTwo: this.value[1] || this.startValue[1] || 100,
            startInputEmpty: this.startEmpty
        }
    },
    computed: {
        classes() {
            return {
                'multi-range_readonly': this.startInputEmpty || this.readonly,
            }
        },
        step() {
            return (this.max - this.min) / (this.pieces - 1)
        },
        range() {
            return this.max - this.min
        },
        maxValue() {
            return max([parseInt(this.valueOne), parseInt(this.valueTwo)])
        },
        minValue() {
            return min([parseInt(this.valueOne), parseInt(this.valueTwo)])
        },
        lineWidth() {
            return Math.abs(
                this.valueOne - this.valueTwo
            ) * 100 / this.range
        },
        left() {
            return (this.minValue - this.min) * 100 / this.range
        },
        styles() {
            const left = this.left + '%'

            return {
                left,
                width: this.lineWidth  + '%'
            }
        },
        rangeValueOne: {
            get() {
                return this.value[0]
            },
            set(value) {
                this.valueOne = value
                this.onChange()
            }
        },
        rangeValueTwo: {
            get() {
                return this.value[1]
            },
            set(value) {
                this.valueTwo = value
                this.onChange()
            }
        }
    },
    watch: {
        value: {
            immediately: true,
            handler(value) {
                if (value && value.length > 0) {
                    this.valueOne = value[0];
                    this.valueTwo = value[1];
                    this.startInputEmpty = false;
                }
            }
        }
    },
    methods: {
        onChange: debounce(function() {
            this.$emit('change', [
                this.valueOne,
                this.valueTwo
            ])
        }, 500),
        reset() {
            this.valueOne = this.startValue[0];
            this.valueTwo = this.startValue[1];
            if (this.startEmpty) {
                this.startInputEmpty = true;
            }
        }
    }
}
</script>

<style lang="less">
.multi-range {
    padding-bottom: rem(16);

    width: 100%;

    position: relative;

    overflow: hidden;

    &__form {
        position: relative;

        overflow: hidden;
    }

    &__bar {
        --z-index: 9;

        position: absolute;
        top: 0;
        left: 0;

        &:first-child {
            --z-index: 6;

            position: relative;
        }

        &::-webkit-slider-thumb {
            position: relative;
            z-index: var(--z-index)
        }

        &::-ms-thumb {
            position: relative;
            z-index: var(--z-index)
        }
        &::-moz-range-thumb {
            position: relative;
            z-index: var(--z-index)
        }
    }

    &_readonly &__line {
        background-color: @color-gray-light;
    }

    &__line {
        background-color: @color-primary;

        height: rem(5);

        position: absolute;
        bottom: rem(16);
    }

    &__header {
        display: flex;
        justify-content: space-between;

        font-size: rem(13);
        font-weight: 500;

        padding: 0 0 rem(10);
    }
}
</style>
