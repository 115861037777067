<i18n>
{
    "ru": {
        "heading": {
            "label": "Предложения от партнеров:",
            "text": "наборы парфюмерных пробников 8 в 1"
        },
        "perfume": {
            "in_set": "Пробник доступен в наборе 8 в 1",
            "not_in_set": "Набор пробников 8 в 1"
        },
        "view": {
            "set": "Смотреть набор пробников",
            "sample": "Смотреть пробник"
        },
        "sets": {
            "one": "набор",
            "few": "набора",
            "many": "наборов",
            "more": "Еще {total}",
            "alt": "Другие наборы пробников Aroma Box"
        }
    }
}
</i18n>

<template>
    <div class="aromabox" :class="{'aromabox_articles': articlesDetail}">
        <div class="aromabox__heading" v-if="showMainHeading">
            <span>{{ $t('heading.label') }}</span>
            {{ $t('heading.text') }}
        </div>
        <div class="aromabox__items">
            <div v-for="(item, index) in items"
                :key="index"
                class="aromabox__item">
                <div class="aromabox__item-heading">
                    <span v-if="isShowItemLabel">{{ getLabel(item) }}</span>
                    <div class="aromabox__item-title">{{ item.title }}</div>
                </div>
                <div class="aromabox__item-content">
                    <div class="aromabox__item-price">
                        <template v-if="getDiscount(item)">
                            <span class="aromabox__item-price-discount">{{ getDiscount(item) }}</span>
                            <span class="aromabox__item-price-old">{{ item.oldPrice | numberFormat }} ₽</span>
                        </template>
                        {{ item.price | numberFormat }} ₽
                    </div>
                    <div class="aromabox__item-links">
                        <a target="_blank"
                            :href="getUrl(item)"
                            class="aromabox__item-link">
                            {{ getLinkText(item) }}
                        </a>
                        <a v-if="!isShowItemLabel"
                            target="_blank"
                            :href="getMoreUrl(item)"
                            class="aromabox__item-link aromabox__item-link_more">
                            {{ getMoreLinkText(item) }}
                        </a>
                    </div>
                </div>
                <a v-if="item.imageUrl || item.picture"
                    :href="getUrl(item)"
                    class="aromabox__item-image"
                    target="_blank"
                    rel="nofollow"
                    :style="'background-image: url('+ (item.imageUrl || item.picture.url) +')'">
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import get from 'lodash/get'
import unescape from 'lodash/unescape'
import { mapGetters } from 'vuex'

export default {
    name: 'AromaBox',
    props: {
        aromabox: {
            type: [Object, Array],
            default: () => {}
        },
        code: {
            type: String,
            default: null
        },
        articlesDetail: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters(['isDeviceDesktop']),
        isCatalog(){
            return Array.isArray(this.aromabox)
        },
        items(){
            if(this.isCatalog)
                return this.isDeviceDesktop ? this.aromabox : [this.aromabox[0]]

            return [this.aromabox]
        },
        isShowItemLabel(){
            if(this.articlesDetail)
                return false

            if(this.isCatalog)
                return !this.isDeviceDesktop

            return true
        },
        showMainHeading(){
            if(this.articlesDetail)
                return true

            return this.isCatalog && this.isDeviceDesktop
        }
    },
    methods: {
        getUrl(aromabox){
            return unescape(get(aromabox, 'htmlUrl', ''))
        },
        getMoreUrl(aromabox){
            return get(aromabox, 'allBoxes.htmlUrl')
        },
        getDiscount(aromabox){
            const discount = get(aromabox, 'discount.percent')
            return discount ? `-${discount}%` : false
        },
        isInSet(aromabox){
            const samplers = get(aromabox, 'perfumeSamplers.perfumes', [])
            return samplers.some(sample => sample.code === this.code)
        },
        getLabel(aromabox){
            return this.isInSet(aromabox) ? this.$t('perfume.in_set') : this.$t('perfume.not_in_set')
        },
        getLinkText(aromabox){
            return this.isInSet(aromabox) ? this.$t('view.sample') : this.$t('view.set')
        },
        getMoreLinkText(aromabox){
            // const total = get(aromabox, 'allBoxes.total', 0)
            // return this.$t('sets.more', {
            //     total: this.setsDeclension(total)
            // })
            return this.$t('sets.alt');
        },
        setsDeclension(count) {
            if(count) {
                const declensionArray = [
                    this.$t('sets.one'),
                    this.$t('sets.few'),
                    this.$t('sets.many')
                ];
                return this.$options.filters.numberDeclension(count, declensionArray)
            }
        }
    }
}
</script>

<style lang="less">
@import 'style';
</style>