<i18n>
    {
        "ru": {
            "gender-male": "Мужской",
            "gender-female": "Женский",
            "gender-unisex": "Унисекс"
        }
    }
</i18n>
<template>
    <div
        @click="$emit('handleClick', id)"
        class="block-offer-item"
        :class="rootClasses" >
        <div
            :class="pictureBlockClasses"
            class="block-offer-item__picture-block">
            <img
                :class="pictureClasses"
                class="block-offer-item__picture"
                v-lazy-load :data-src="picture || getEntityNoImage('perfumeBg')"
                :alt="alt || ''" />
            <div v-if="stickerContent" class="block-offer-item__sticker_discount">
                {{ stickerContent }}
            </div>
        </div>
        <div class="block-offer-item__content" :class="contentClasses">
            <!-- eslint-disable-next-line -->
            <div v-if="name" class="block-offer-item__title" v-html="name"></div>

            <div class="block-offer-item__brand"
                v-if="brandName">
                {{ brandName }}
            </div>

            <div v-if="!withoutCaption && caption" class="block-offer-item__caption">
                {{ caption }}
            </div>

            <div v-if="!isModeSmallSize" class="block-offer-item__footer">
                <a class="block-offer-item__link"
                    v-if="link && linkName"
                    rel="nofollow"
                    target="_blank"
                    :href="link"
                    :aria-label="linkName">
                    {{ linkName }}
                </a>
                <html-link
                    v-else-if="routeTo"
                    :url="routeTo"
                    :aria-label="name || 'route'"
                    class="block-offer-item__link block-offer-item__link_full" />
                <html-link
                    v-else-if="urlWithContent && url"
                    :url="url"
                    :aria-label="name"
                    class="block-offer-item__link block-offer-item__link_full" >
                    <span class="block-offer-item__link-content-hide">
                        {{ name }}
                        <br/>
                        {{ brandName }}
                        <br/>
                        {{ caption }}
                    </span>
                </html-link>
                <html-link
                    v-else-if="url"
                    :url="url"
                    :aria-label="name"
                    class="block-offer-item__link block-offer-item__link_full" />
                <div v-if="price" class="block-offer-item__price">
                    {{ price | numberFormat }} <span class="block-offer-item__currency"></span>
                </div>
            </div>
        </div>
        <div class="block-offer-item__panel-right">
            <div
                class="rating block-offer-item__rating"
                v-if="withRating && computedRating"
                :data-value="computedRating">
            </div>
            <html-link v-if="withComment"
                :url="url + '#comments'"
                class="panel-right__row messages-count">
                <svg-icon
                    name="icon-message-circle"
                    class="messages-count__icon icon__message" />
                {{ commentsCount || 0 }}
            </html-link>
            <span v-if="producedFrom" class="panel-right__row block-offer-item__date-created">
                {{ producedFrom }}
            </span>
            <span v-if="genderCode" class="panel-right__row block-offer-item__gender">
                {{ $t('gender-' + genderCode) }}
            </span>
            <div v-if="actionClose" class="block-offer-item__action_close">
                <svg-icon
                    name="icon-close"
                    class="block-offer-item__action-icon"
                    @click="$emit('handleClose', offer)" />
            </div>
            <slot name="text-right"></slot>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import OfferMixin from '@/mixins/catalog/offers.mixin'

export default {
    name: 'BlockOffer',
    mixins: [ OfferMixin ],
    props: {
        brandName: {
            type: String,
            default: null
        },
        id: {
            type: [Number, String],
            default: 0
        },
        withoutCaption: {
            type: Boolean,
            default: false
        },
        externalLink: {
            type: Boolean,
            default: false
        },
        viewSize: {
            type: String,
            default: 'm'
        },
        pictureSize: {
            type: String,
            default: 'square'
        },
        actionClose: {
            type: Boolean,
            default: false
        },
        searchMode: {
            type: Boolean,
            default: false
        },
        stickerContent: {
            type: [String, Number],
            default: null
        },
        urlWithContent: {
            type: Boolean,
            default: false
        },
        alt: {
            type: String,
            default: null
        }
    },
    computed: {
        ...mapGetters(['isDeviceDesktop']),
        isModeSmallSize() {
            return this.viewSize === 's'
        },
        contentClasses() {
            return {
                'block-offer-item__content_centered': this.centeredContent
            }
        },
        rootClasses() {
            return {
                'block-offer-item_small': this.isModeSmallSize,
                'block-offer-item_search-mode': this.searchMode,
                'block-offer-item_column': this.directionColumn
            }
        },
        pictureClasses() {
            return {
                'block-offer-item__picture_album': this.pictureSize === 'album'
            }
        },
        pictureBlockClasses() {
            return {
                'block-offer-item__picture-block_album': this.pictureSize === 'album'
            }
        }
    },
};
</script>


<style lang="less">
.block-offer-item {
    --height-item-block: rem(103);
    --size-picture: rem(72);

    display: flex;

    padding: rem(8) rem(20) rem(10) rem(20);

    height: var(--height-item-block);
    width: 100%;

    position: relative;

    @{desktop} & {
        padding-right: 0;
        transition: border 0.2s, box-shadow .2s ease;
        padding-left: 0;
    }

    &:not(:last-child) {
        border-bottom: rem(1) solid @color-gray-white;
    }

    &__brand {
        // color: @color-gray-light;
        color: @color-gray-contrast;
        font-size: rem(12);
        font-weight: 500;
        letter-spacing: 0.04em;
        line-height: 1.25;
        text-transform: uppercase;

        margin-top: rem(2);
        margin-bottom: rem(2);
    }

    &__rating {
        margin-left: rem(5);
        height: rem(13);
        left: rem(1);
        z-index: 0;
        flex: 0 0 rem(13);

        @{mobile} & {
            --star-width: rem(15);
            --star-height: rem(13);
        }

        @{desktop} & {
            --star-width: rem(15);
            --star-height: rem(13);
        }

        .block-offer-item_column & {
            @{desktop} & {
                --star-width: rem(15);
                --star-height: rem(13);
                margin-left: 0;
                left: 0;
                flex: none;
            }

            @{mobile} & {
                --star-width: rem(15);
                --star-height: rem(13);
                margin-left: 0;
                left: 0;
                flex: none;
            }
        }
    }

    &__gender {
        text-overflow: ellipsis;
        white-space: nowrap;

        overflow: hidden;
    }

    &__content-wrapper {
        width: 100%;
    }

    &__panel-right {
        display: flex;
        flex-direction: column;

        color: @color-gray;
        font-size: rem(13);
        text-align: right;

        margin-top: rem(6);

        max-width: rem(80);

        .block-offer-item_column & {
            text-align: center;

            margin-top: rem(8);

            max-width: 100%;

            @{desktop} & {
                margin-top: rem(2);
            }
        }
    }

    &__picture {
        .center-image();

        object-fit: contain;

        line-height: var(--size-picture);

        /*max-height: var(--size-picture);*/
        margin-top: rem(7.5);
        margin-right: rem(7.5);

        height: var(--size-picture);
        width: var(--size-picture);

        .block-offer-item_column & {
            margin: 0;
        }

        &-block {
            display: flex;
            justify-content: center;

            width: var(--size-picture);

            position: relative;

            &_album {
                --size-picture: rem(71);

                align-items: center;
                justify-content: flex-start;

                margin-top: rem(7.5);
                margin-right: rem(7.5);

                width: var(--size-picture);
            }
        }

        &_album {
            --picture-width: rem(71);
            --picture-height: rem(40);
            margin-top: 0;
            margin-right: 0;

            height: var(--picture-height);
            width: var(--picture-width);
        }
    }

    &__sticker {
        &_discount {
            display: flex;
            align-items: center;
            justify-content: center;

            background: @color-white;
            border: rem(1) solid @color-gray-white;
            border-radius: 50%;
            font-size: rem(11);
            font-weight: 500;

            padding: rem(5);
            padding-top: rem(7);

            height: rem(32);
            width: rem(32);

            position: absolute;
            top: rem(5);
            left: rem(-7);
        }
    }

    &__content {
        margin-top: rem(13);
        margin-left: rem(8);

        width: 100%;

        overflow: hidden;

        &_centered {
            display: flex;
            align-items: center;
        }

        .block-offer-item_column & {
            text-align: center;

            margin-top: rem(16);
            margin-left: 0;
        }
    }

    &__footer {
        display: flex;
        justify-content: space-between;

        width: 100%;
    }

    &__title {
        font-weight: 500;
        text-overflow: ellipsis;
        white-space: nowrap;

        margin-bottom: rem(3);

        max-height: rem(46);

        overflow: hidden;

        & > i {
            display: inline-block;

            font-style: normal;

            margin-top: rem(-2);
            padding: rem(2) rem(3) rem(0);

            position: relative;
        }
    }

    &__caption {
        display: -webkit-box;

        color: @color-gray;
        font-size: rem(13);
        text-overflow: ellipsis;

        margin-bottom: rem(3);

        overflow: hidden;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    &__price {
        font-size: rem(13);
        font-weight: 500;
    }

    &__currency {
        text-indent: -9999px;

        &:after {
            content: '₽';

            text-indent: 0;
        }
    }

    &__link {
        color: @color-primary;
        font-size: rem(13);
        text-decoration: underline;

        &:after {
            content: '';

            position: absolute;
            top: rem(10);
            right: rem(5);
            bottom: rem(10);
            left: rem(5);
        }

        &-content-hide {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: -1;

            visibility: hidden;
            opacity: 0;

            transform: translate(-50%, -50%);
        }

        &_full {
            position: absolute;
            top: rem(10);
            right: rem(5);
            bottom: rem(10);
            left: rem(5);
            z-index: 1;

            &:after {
                display: none;
            }
        }
    }

    .icon {
        &__message {
            height: rem(16);
            width: rem(16);
        }
    }

    .messages-count {
        // color: @color-gray-light;
        color: #6d6d6d;
        font-size: rem(13);
        font-weight: 500;
        text-overflow: ellipsis;
        white-space: nowrap;
        align-self: flex-end;
        position: relative;
        z-index: 2;

        margin: rem(4) 0 rem(2);

        overflow: hidden;

        &__icon {
            margin-top: rem(-1);
            margin-right: rem(1);
        }
    }

    a.messages-count {
        .hover({
            color: @color-black;
        });
    }

    &__action_close {
        display: flex;
        align-items: center;

        padding-right: rem(10);

        height: 100%;
    }

    &__action-icon {
        height: rem(20);
        width: rem(20);
    }

    &_search-mode {
        .block-offer-item__title {
            & > i {
                display: inline-block;

                background: fade(@color-violet, 10%);
                font-style: normal;

                margin-top: rem(-2);
                padding: rem(2) rem(3) rem(0);

                position: relative;
            }
        }
    }

    &_small {
        --size-picture: rem(50);
        --height-item-block: rem(64);
        padding: 0 rem(20) 0;
    }

    &_column {
        --size-picture: rem(88);
        --height-item-block: rem(302);
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;

        padding: rem(20) rem(6) ;

        .messages-count {
            margin: rem(10) 0 rem(6);
            align-self: center;
        }

        @{desktop} & {
            --height-item-block: initial;

            justify-content: flex-start;

            padding-right: rem(20);
            padding-bottom: rem(22);
            padding-left: rem(20);
        }

        .block-offer-item__title{
            @{desktop} &{
                max-height: rem(69);
            }
        }

        .hover({
            background: @color-white;
            box-shadow: 0 rem(1) rem(8) rgba(0, 0, 0, 0.1);
            z-index: 9;

            .block-offer-item{
                &__title{
                    white-space: normal;
                }
                &__content{
                    white-space: normal;
                    max-height: none;
                    overflow: visible;
                }
                &__caption {
                    -webkit-line-clamp: initial;
                }
            }
        });
    }
}

.panel-right {
    &__row {
        margin-bottom: rem(1);
    }
}
</style>

