<i18n>
{
    "ru": {
        "title": "Каталог парфюмерии",
        "introtext": "Самая полная информация об ароматах, парфюмерах, брендах и нотах: все, что вы искали, — в каталоге Aromo. Полные портфолио бюджетных, люксовых и нишевых марок парфюмерии.",
        "q_placeholder": "Введите название аромата...",
        "date": "По дате выпуска",
        "rating": "По рейтингу",
        "popular": "По популярности",
        "alphabet": "По алфавиту",
        "by_filter": "(по фильтру)",
        "best_articles": "Лучшие статьи",
        "chained_pages": "Интересные подборки",
        "meta": {
            "title": "Каталог парфюмерии: все о редких и популярных ароматах",
            "description": "Самый полный каталог парфюмерии на русском языке. Информация о нотах, парфюмерах и новинках, а также независимые отзывы пользователей и рейтинг популярности — на Aromo."
        },
        "add_perfume": "Добавить аромат"
    }
}
</i18n>

<template>
    <div class="perfumes catalog-page">

        <block-price-panel />

        <Breadcrumb 
            :items="breadcrumbs"
            :show-prev-button="showPrevBtn"
            :prev-path-url="prevRoute"
            class="catalog-page__breadcrumbs"/>

        <div class="catalog-page__header">
            <h1 class="catalog-page__title">
                {{ meta.h1 || metaDefault.h1 }}
            </h1>
            <!-- eslint-disable-next-line -->
            <div v-html="totalPhrase"
                class="catalog-page__total">
            </div>
            <template v-if="isDeviceDesktop">
                <!-- eslint-disable-next-line -->
                <div v-html="introtext" 
                    v-if="isShowDescription"
                    class="catalog-page__description">
                </div>
            </template>
            <template v-else>
                <ShowMore
                    v-if="isShowDescription"
                    :max-rows="6"
                    class="catalog-page__description"
                    ref="introtext">
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <div class="html-content html-content_sidebar" v-html="introtext"></div>
                </ShowMore>
            </template>
            <span 
                class="btn btn_primary catalog-page__add-item"
                @click="onEditClick">
                {{ $t('add_perfume') }}
            </span>
        </div>

        <AromaBox class="catalog-page__aromabox"
            :aromabox="perfumesAromabox" />

        <div class="catalog-page__body">
            <div v-if="isDeviceDesktop"
                class="catalog-page__sidebar">
                <PerfumesFilter 
                    ref="filter"
                    :fields="filterFields" 
                    :settedValues="filterParams" 
                    :additionalValues="{q}"
                    @submit="onFilterSubmit"
                    @reset="onFilterReset" />
            </div>
            <div class="catalog-page__main">
                <CatalogList
                    ref="catalog"
                    :grid="isDeviceDesktop"
                    :grid-columns="isDeviceDesktop ? 4 : null"
                    :placeholder="$t('q_placeholder')"
                    :search-loading="qLoading"
                    :query="q"
                    @search="q = $event"
                    @change-sort-type="onChangeSort"
                    @change-sort-order="onChangeOrder"
                    @reset-all="resetAll"
                    :with-pagination="parseInt(lastPage) > 1"
                    with-sort
                    sort-in-search-field
                    :with-filter="!isDeviceDesktop"
                    @show-filter="filterIsShowed = true"
                    :filters-count="filtersCount"
                    v-model="page"
                    :page-count="lastPage"
                    :sort-options="sortOptions"
                    :sort-order="order"
                    :sort-type="sort"
                    class="catalog-page__perfumes"
                    :items="items">

                    <template #item="{ item }">
                        <BlockOffer
                            :name="item.name"
                            :url="item.htmlUrl"
                            url-with-content
                            :picture="item.picture ? item.picture.url : null"
                            :produced-from="item.produced ? item.produced.from : null"
                            :brand-name="item.brand ? item.brand.name : null"
                            :gender-code="item.gender ? item.gender.code : null"
                            :rating="item.ratings ? item.ratings.value : null"
                            with-rating
                            :direction-column="isDeviceDesktop"
                            :comments-count="item.comments ? item.comments.total : null"
                            :caption="item.concentration ? item.concentration.name : 0"/>
                    </template>
                </CatalogList>

                <client-only>
                    <RecentItems class="catalog-page__recent" />
                </client-only>

                <PageBlock v-if="!isDeviceDesktop && relatedArticles" 
                    :title="$t('best_articles')"
                    class="catalog-page__page-block">
                    <ArticleRowItem
                        :key="index + article"
                        v-for="(article, index) in relatedArticles"
                        :data="article"/>
                </PageBlock>
                <PageBlock v-if="!isDeviceDesktop && relatedPages" 
                    :title="$t('chained_pages')"
                    class="catalog-page__page-block">
                    <LinkedPagesList :items="relatedPages"/>
                </PageBlock>

                <transition v-if="!isDeviceDesktop" name="fade">
                    <ModalFilter
                        v-show="filterIsShowed"
                        :disable-modal-parent-scroll="filterIsShowed"
                        @close-sidebar="filterIsShowed = false"
                        :isOpened="filterIsShowed"
                        :filtersCount="filtersCount"
                        :params="filterParams">
                        <template #filter-body>
                            <PerfumesFilter 
                                ref="filter"
                                :fields="filterFields" 
                                :settedValues="filterParams" 
                                :additionalValues="{q}"
                                @change="filtersCount = $event.count"
                                @submit="onFilterSubmit"
                                @reset="onFilterReset"
                                class="cat-filter_modal" />
                        </template>
                    </ModalFilter>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import get from 'lodash/get';

import Breadcrumb from '@/components/layout/nav/Breadcrumb';
import CatalogPageMixin from '@/components/catalog/mixins/CatalogPage.mixin';
import PerfumesFilter from '@/components/catalog/filters/PerfumesFilter';
import ModalFilter from '@/components/catalog/filters/parts/ModalFilter';
import BlockOffer from '@/components/catalog/BlockOffer';
import CatalogList from '@/components/catalog/CatalogList';

import PageBlock from '@/components/layout/AppPageBlock';
import ArticleRowItem from '@/components/layout/blocks/ArticlesList/ArticleRowItem';
import LinkedPagesList from '@/components/layout/blocks/LinkedPagesList';
import BlockPricePanel from '@/components/catalog/BlockPricePanel';
import AromaBox from '@/components/catalog/aromabox';
import RecentItems from '@/components/catalog/RecentItems';

import Perfume from '@/models/catalog/perfume';
import FilterField from '@/models/services/field';
import Filter from '@/models/services/filter';
import url from '@/filters/url';

export default {
    name: 'Perfumes',
    components: {
        Breadcrumb,
        PerfumesFilter,
        BlockOffer,
        ModalFilter,
        PageBlock,
        ArticleRowItem,
        LinkedPagesList,
        CatalogList,
        BlockPricePanel,
        AromaBox,
        RecentItems
    },
    mixins: [CatalogPageMixin],
    fetchOnServer: false,
    async asyncData({ app, route, query, store, error }) {

        const perfumeModel = new Perfume();
        const filterModel = new Filter();
        const filterFields = new FilterField().custom('services', filterModel, perfumeModel, 'fields');
        const filterParse = new FilterField().custom('services', filterModel, perfumeModel, 'parse-url');

        const samePage = process.browser && get(window, 'history.state.refreshed', false) === route.name;
        const refresh = !(process.server || !samePage);
        const path = samePage ? window.location.pathname : route.path;
        const queryParams = process.server || !samePage ? query : url.parseUrlQuery(window.location.href);
        const filterQueryString = url.queryFilterToRestString(queryParams);
        const isIndexPage = path === route.matched[0].path;
        const slugWithoutPage = url.modify(path, {page: null});

        let parseObj = {};
        let parseParams = {slug: path};

        const qParams = {
            q: queryParams.q ? encodeURIComponent(decodeURIComponent(queryParams.q)) : null,
            page: url.getPageNumber(path) || 1,
            sort: queryParams.sort || 'popularity',
            order: queryParams.order || 'desc',
            per_page: 48
        };

        if (!isIndexPage || filterQueryString) {
            parseParams = slugWithoutPage !== route.matched[0].path ? {slug: path} : {params: filterQueryString};
            parseObj = await filterParse
                .params(Object.assign(parseParams, qParams))
                .get()
                .catch((e) => {
                    error({statusCode: e.response.status, message: e.response.data});
                });
        }   

        if (refresh) {
            if (process.browser) {
            // if (window.pageYOffset > window.innerHeight/2) {
                app.$scrollTo('.catalog-list', 950, {
                    offset: store.getters.isDeviceDesktop ? 0 : -54
                });
            // }
            }
        }

        const filterParams = get(parseObj, '[0].params', {});
        const meta = get(parseObj, '[0].meta', {});

        const requests = [
            perfumeModel.params(Object.assign(qParams, filterParams)).get(),
            filterFields.params(parseParams).get(),
            app.$api.get('/perfumes/aroma-boxes'),
            !refresh && !store.getters.isDeviceDesktop ? perfumeModel.relatedArticles().get() : null,
            !refresh && !store.getters.isDeviceDesktop ? perfumeModel.relatedPages().get() : null
        ];

        return Promise.all(requests.filter(word => word))
            .then((results) => {
                
                const data = {
                    total: get(results, '[0][0][total]'),
                    items: get(results, '[0][0][items]'),
                    lastPage: get(results, '[0][0][lastPage]'),
                    filterFields: results[1][0],
                    q: qParams.q,
                    sort: qParams.sort,
                    order: qParams.order,
                    page: qParams.page,
                    filterParams,
                    meta,
                    nextPage: get(results, '[0][0].nextPage', null),
                    prevPage: get(results, '[0][0].prevPage', null),
                    perfumesAromabox: get(results, '[2].data')
                };

                if (results[3]) {
                    data.relatedArticles = results[3];
                };

                if (results[4]) {
                    data.relatedPages = results[4];
                };

                if (process.server) {
                    app.store.dispatch('rest/headers/filterLastModified', {
                        url: [`/perfumes`]
                    });
                }

                return data;
            })
            .catch((e) => {
                error({statusCode: e.response.status, message: e.response.data});
            });
    },
    data() {
        return {
            breadcrumbs: [
                {
                    path: this.$i18n.__baseUrl,
                    name: this.$t('breadcrumbs.home_page'),
                },
                {
                    path: this.$i18n.__baseUrl + this.$router.resolve({name: 'Perfumes'}).href,
                    name: this.$t('breadcrumbs.catalog_page'),
                }
            ],
            total: 0,
            items: [],
            lastPage: 1,
            filterFields: {},
            filterParams: {},
            sortOptions: {
                popularity: {
                    name: this.$t('popular')
                },
                rating: {
                    name: this.$t('rating')
                },
                year: {
                    name: this.$t('date')
                },
                name: {
                    name: this.$t('alphabet')
                },
            },
            sort: 'popularity',
            order: 'desc',
            q: null,
            qLoading: false,
            filterIsShowed: false,
            filtersCount: 0,
            page: 1,
            meta: {},
            declensions: this.$t('declensions.perfumes'),
            prevRoute: null,
            relatedArticles: [],
            relatedPages: [],
            perfumesAromabox: []
        }
    },
    computed: {
        showPrevBtn() {
            return this.prevRoute !== null
        },
        metaDefault() {
            return {
                h1: this.$t('title'),
                title: this.$t('meta.title'),
                description: this.$t('meta.description')
            }
        },
        introtext(){
            return get(this.meta, 'pageDescription', this.$t('introtext'))
        },
        isShowDescription(){
            // показываем описание только на первых страницах при фильтрации
            if(this.filterParams && Object.keys(this.filterParams).length > 0)
                return this.page === 1

            return true
        }
    },
    watch: {
        introtext(){
            if(this.$refs.introtext)
                this.$refs.introtext.init()
        }
    },
    methods: {
        onEditClick(){
            this.$authEmit('open-edit-popup')
        }
    },
    globalBus: {
        'auth-complete'() {
            this.$nextTick(() => {
                this.$bus.$emit('fetch-complete')
            })
        },
        'open-edit-popup'(){
            this.showPopup('add-perfume')
        }
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            if (from.name === 'users.favorites') {
                vm.prevRoute = from.path
            } else {
                vm.prevRoute = null
            }
        })
    }
}
</script>

<style lang="less">
.catalog-page{

    &__header{
        position: relative;
    }

    &__recent{
        @{mobile} & {
            margin-bottom: 0;

            .page-block_recent{
                border-top: rem(16) solid @color-bg-gray;
                margin-bottom: 0;
            }
        }
    }

    &__perfumes{
        text-align: left;

        @{desktop} & {
            --grid-item-height: rem(298);
            padding-top: rem(10);
            margin-bottom: rem(80);

            &:last-child{
                margin-bottom: 0;
            }

            .rating{
                height: rem(13);
            }
        }

        .search-field__field_with-sort{
            @{desktop} &{
                max-width: 70%;
            }
        }
    }
}
</style>